<template>
    <v-card tile color="transparent" loader-height="100%" class="pa-18px px-md-20px py-md-24px">
        <div v-if="$slots.head" class="txt txt--sm font-weight-medium grey--text text--darken-4 mb-12px">
            <slot name="head" />
        </div>
        <div v-if="$slots.default">
            <slot />
        </div>
        <div v-if="$slots.foot">
            <slot name="foot" />
        </div>
    </v-card>
</template>

<script>
export default {};
</script>

<style></style>
