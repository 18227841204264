<template>
    <v-tab-item :value="BONUS_WITHDRAW_TABS.VERIFY_USER_IDENTITY.value">
        <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">
            본인인증 후 출금이 완료됩니다.
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col cols="6">
                    <v-btn block outlined color="grey" class="v-size--xx-large" v-bind="{ loading }" @click="prev">이전</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="$refs['kcp-cert']?.auth?.()">본인인증 후 출금</v-btn>
                    <kcp-cert ref="kcp-cert" @input="withdraw" />
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import banks from "@/plugins/shop-banks.json";
import { BONUS_WITHDRAW_TABS, initBonus__debit } from "@/assets/variables";

import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: Object, default: initBonus__debit }, // withdraw form
    },
    data: () => ({
        loading: false,
        BONUS_WITHDRAW_TABS,
    }),
    computed: {
        ...mapState(["user"]),
        hasNoBank() {
            return !this.bank?.code || !this.bank?.accountNumber || !this.bank?.accountHolder;
        },
        bank() {
            return this.user?.bank || {};
        },
        bankName() {
            return banks?.find?.(({ code }) => code == this.bank?.code)?.name;
        },
    },
    methods: {
        prev() {
            this.$emit("setTab", BONUS_WITHDRAW_TABS.CONFIRM_BANK_ACCOUNT.value);
        },
        next() {
            this.$emit("setTab", BONUS_WITHDRAW_TABS.CONFIRM_DEPOSIT_DONE.value);
        },
        async withdraw({ _certification }) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const form = {
                    ...this.value,
                    _certification,
                };
                await api.v1.promoter.bonuses.withdraw(form);
                this.next();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
