var render = function render(){
  var _vm$user, _vm$user2, _vm$user2$bonus, _vm$user2$bonus$forma;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-18px pa-md-24px text-center"
  }, [_c('v-avatar', {
    staticClass: "mb-8px mb-md-12px",
    attrs: {
      "size": "60",
      "color": "white"
    }
  }, [_c('icon-funds')], 1), _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-4px"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " 님의 현재 보유 보너스")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-24 font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : (_vm$user2$bonus = _vm$user2.bonus) === null || _vm$user2$bonus === void 0 ? void 0 : (_vm$user2$bonus$forma = _vm$user2$bonus.format) === null || _vm$user2$bonus$forma === void 0 ? void 0 : _vm$user2$bonus$forma.call(_vm$user2$bonus)) || 0) + "원")]), _c('div', {
    staticClass: "pt-8px"
  }, [_c('bonus-withdraw-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, attrs), false), on), [_vm._v("보너스 출금하기")])];
      }
    }])
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }