<template>
    <client-page>
        <mypage-layout title="대시보드" aside="promoter">
            <!-- S: 인포카드 -->
            <section-info-card v-bind="{ user }" />
            <!-- E: 인포카드 -->

            <!-- S: 우대회원 달성 자격조건 -->
            <page-section class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <section-vip-condition />
                </template>
            </page-section>
            <!-- E: 우대회원 달성 자격조건 -->

            <!-- S: 매출통계 -->
            <page-section class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <section-sales-stats />
                </template>
            </page-section>
            <!-- E: 매출통계 -->

            <!-- S: 공지사항 -->
            <page-section class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <tit-wrap-line noDivider title="공지사항"></tit-wrap-line>
                    <router-view name="board" code="promoter-notice-board" skin="board-notice" />
                </template>
            </page-section>
            <!-- E: 공지사항 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import SectionInfoCard from "@/components/client/promoter/dashboard/section-info-card.vue";
import SectionSalesStats from "@/components/client/promoter/dashboard/section-sales-stats.vue";
import SectionVipCondition from "@/components/client/promoter/dashboard/section-vip-condition.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,
        SectionInfoCard,
        SectionSalesStats,
        SectionVipCondition,
    },
    computed: {
        ...mapState(["user"]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
            this.replacePath();
        },
        replacePath() {
            let path = "/promoter/dashboard";
            if (!this.$route.path.includes(path)) this.$router.replace({ path });
        },
    },
};
</script>

<style></style>
