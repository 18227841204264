<template>
    <v-tab-item :value="BONUS_WITHDRAW_TABS.CONFIRM_BANK_ACCOUNT.value">
        <template v-if="hasNoBank">
            <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">
                출금 계좌정보가 없습니다.<br />
                출금 계좌정보를 등록해 주세요.
            </div>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col cols="6">
                        <v-btn block outlined color="grey" class="v-size--xx-large" @click="prev">이전</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block color="primary" class="v-size--xx-large" to="/promoter/bank">계좌번호 등록</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
        <template v-else>
            <tit-wrap-x-small title="계좌번호를 확인해 주세요."></tit-wrap-x-small>

            <page-section class="page-section--xs py-0">
                <v-divider />
                <div class="py-12px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">
                            은행명
                        </v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium grey--text text--darken-4">{{ bankName || "-" }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-divider />
                <div class="py-12px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">
                            계좌번호
                        </v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium grey--text text--darken-4">{{ bank?.accountNumber || "-" }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-divider />
                <div class="py-12px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">
                            예금주
                        </v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium grey--text text--darken-4">{{ bank?.accountHolder || "-" }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-divider />
            </page-section>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col cols="6">
                        <v-btn block outlined color="primary" class="v-size--xx-large" to="/promoter/bank">계좌번호 변경</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block color="primary" class="v-size--xx-large" @click="next">다음</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-tab-item>
</template>

<script>
import { mapState } from "vuex";
import banks from "@/plugins/shop-banks.json";
import { BONUS_WITHDRAW_TABS, initBonus__debit } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: initBonus__debit }, // withdraw form
    },
    data: () => ({
        BONUS_WITHDRAW_TABS,
    }),
    computed: {
        ...mapState(["user"]),
        hasNoBank() {
            return !this.bank?.code || !this.bank?.accountNumber || !this.bank?.accountHolder;
        },
        bank() {
            return this.user?.bank || {};
        },
        bankName() {
            return banks?.find?.(({ code }) => code == this.bank?.code)?.name;
        },
    },
    methods: {
        prev() {
            this.$emit("setTab", BONUS_WITHDRAW_TABS.CONFIRM_BONUS_AMOUNT.value);
        },
        next() {
            const { bank } = this;
            this.$emit("input", initBonus__debit({ ...this.value, meta: { ...(this.value?.meta || {}), bank } }));
            this.$emit("setTab", BONUS_WITHDRAW_TABS.VERIFY_USER_IDENTITY.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.text-align-right-input {
    ::v-deep input {
        text-align: right;
    }
}
</style>
