var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    }
  }, _vm._l(_vm.tabs, function (_ref) {
    var text = _ref.text,
      to = _ref.to;
    return _c('v-tab', _vm._b({
      key: text,
      attrs: {
        "exact": ""
      }
    }, 'v-tab', {
      to
    }, false), [_vm._v(_vm._s(text))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }