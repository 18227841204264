var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "대시보드",
      "aside": "promoter"
    }
  }, [_c('section-info-card', _vm._b({}, 'section-info-card', {
    user: _vm.user
  }, false)), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('section-vip-condition')], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('section-sales-stats')], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "공지사항"
    }
  }), _c('router-view', {
    attrs: {
      "name": "board",
      "code": "promoter-notice-board",
      "skin": "board-notice"
    }
  })], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }