<template>
    <v-tab-item :value="BONUS_WITHDRAW_TABS.CONFIRM_BONUS_AMOUNT.value">
        <tit-wrap-x-small title="출금할 보너스 입력">
            <span class="font-size-12 red--text">*출금 시 1,000원의 수수료가 발생합니다.</span>
        </tit-wrap-x-small>

        <div>
            <v-card rounded="md" color="grey lighten-5">
                <div class="pa-16px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">
                            보유 보너스
                        </v-col>
                        <v-col cols="auto">
                            <span class="d-flex align-center font-size-16 font-size-md-20 primary--text">
                                <strong>{{ (user?.bonus || 0)?.format?.() }}</strong>
                                <small class="font-size-14 font-weight-regular pl-2px">원</small>
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <div class="pt-12px">
                <v-currency-field v-model="form.amount" :max="user?.bonus - charge" v-bind="{ ...attrs_input }" :dense="false" suffix="원" class="text-align-right-input" />
            </div>
        </div>

        <page-section class="page-section--xs pb-0">
            <v-divider />
            <div class="py-12px">
                <v-row no-gutters justify="space-between" class="txt txt--xs">
                    <v-col cols="auto">
                        수수료
                    </v-col>
                    <v-col cols="auto">
                        <span class="font-weight-medium grey--text text--darken-4">{{ charge?.format?.() }} 원</span>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
            <div class="py-12px">
                <v-row no-gutters justify="space-between" class="txt txt--xs">
                    <v-col cols="auto">
                        출금 후 잔액
                    </v-col>
                    <v-col cols="auto">
                        <span class="font-weight-medium grey--text text--darken-4">{{ remain?.format?.() }} 원</span>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
        </page-section>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col cols="6">
                    <v-btn block outlined color="grey" class="v-size--xx-large" @click="$emit('close')">취소</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="next">보너스 출금</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import { mapState } from "vuex";
import { attrs_input, BONUS_WITHDRAW_TABS, initBonus__debit } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: initBonus__debit }, // withdraw form
    },
    data: () => ({
        form: initBonus__debit(),

        attrs_input,
        BONUS_WITHDRAW_TABS,
    }),
    computed: {
        ...mapState(["user"]),
        charge() {
            return this.form?.amount ? 1000 : 0;
        },
        remain() {
            return (+this.user?.bonus || 0) - (+this?.form?.amount || 0) - (this.charge || 0);
        },
        disabled() {
            return !(0 < this.form?.amount && this.form?.amount + this.charge <= this.user?.bonus);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initBonus__debit(this.value);
        },
        next() {
            const { charge } = this;
            this.$emit("input", initBonus__debit({ ...this.form, meta: { ...(this.form?.meta || {}), charge } }));
            this.$emit("setTab", BONUS_WITHDRAW_TABS.CONFIRM_BANK_ACCOUNT.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.text-align-right-input {
    ::v-deep input {
        text-align: right;
    }
}
</style>
