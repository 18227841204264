<template>
    <div>
        <tit-wrap-line noDivider title="매출통계"></tit-wrap-line>

        <v-card rounded="" color="grey lighten-5">
            <v-row no-gutters>
                <!-- S: 당일 매출통계 -->
                <v-col cols="12" sm="6">
                    <card-sheet>
                        <template #head>
                            <span class="red--text">당일 매출통계</span>
                        </template>
                        <div v-for="({ term, data, attrs = {} }, index) in items__today" :key="term" v-bind="attrs" :class="{ 'mt-8px': index != 0 }">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto" :class="term == '총 매출' ? 'primary--text' : ''">
                                    {{ term }}
                                </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium" :class="term == '총 매출' ? 'primary--text' : 'grey--text text--darken-4'">{{ data }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </card-sheet>
                </v-col>
                <!-- E: 당일 매출통계 -->

                <!-- S: 당월 매출통계 -->
                <v-col cols="12" class="d-sm-none"><v-divider /></v-col>
                <v-col cols="auto" class="d-none d-md-block ml-n1px">
                    <div class="h-100 py-18px py-md-24px">
                        <v-divider vertical />
                    </div>
                </v-col>
                <v-col cols="12" sm="6">
                    <card-sheet>
                        <template #head>
                            <span class="red--text">당월 매출통계</span>
                        </template>
                        <div v-for="({ term, data, attrs = {} }, index) in items__thisMonth" :key="term" v-bind="attrs" :class="{ 'mt-8px': index != 0 }">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto" :class="term == '총 매출' ? 'primary--text' : ''">
                                    {{ term }}
                                </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium" :class="term == '총 매출' ? 'primary--text' : 'grey--text text--darken-4'">{{ data }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </card-sheet>
                </v-col>
                <!-- E: 당월 매출통계 -->
            </v-row>
        </v-card>
    </div>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import CardSheet from "./card-sheet.vue";

const initStats = () => ({
    consumers: 0,
    promoters: 0,
    vipprmtrs: 0,
    promotees: 0,
});

const makeDurationWithUnitOf = (unit) => [dayjs().startOf(unit), dayjs().endOf(unit)].map((dayjs) => dayjs.toISOString());

export default {
    components: {
        TitWrapLine,
        CardSheet,
    },
    data: () => ({
        salesStats__today: initStats(),
        salesStats__thisMonth: initStats(),
    }),
    computed: {
        items__today() {
            const { consumers, promoters, vipprmtrs, promotees } = this.salesStats__today;
            return [
                { term: "준회원 매출", data: consumers },
                { term: "정회원 매출", data: promoters },
                { term: "우수회원 매출", data: vipprmtrs },
                { term: "총 매출", data: promotees },
            ].map((item) => ({ ...item, data: item.data?.format?.() }));
        },
        items__thisMonth() {
            const { consumers, promoters, vipprmtrs, promotees } = this.salesStats__thisMonth;
            return [
                { term: "준회원 매출", data: consumers },
                { term: "정회원 매출", data: promoters },
                { term: "우수회원 매출", data: vipprmtrs },
                { term: "총 매출", data: promotees },
            ].map((item) => ({ ...item, data: item.data?.format?.() }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const type = "promotees";
            const responseType = "salesStats";

            this.salesStats__today = (await api.v1.promoter.purchases.gets({ params: { type, responseType, completedAt: makeDurationWithUnitOf("day") } }))?.salesStats;

            this.salesStats__thisMonth = (await api.v1.promoter.purchases.gets({ params: { type, responseType, completedAt: makeDurationWithUnitOf("month") } }))?.salesStats;
        },
    },
};
</script>

<style></style>
