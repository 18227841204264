var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('settlement-monthly-table', _vm._b({}, 'settlement-monthly-table', {
    title: _vm.title,
    makeItems: _vm.makeItems
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }