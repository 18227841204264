<template>
    <div>
        <tit-wrap-line noDivider>
            <template slot="titleContents">
                <div class="d-flex flex-column flex-sm-row align-sm-center">
                    <span class="tit tit--xxs">현재 등급</span>
                    <span class="txt txt--sm pl-sm-8px mt-4px mt-sm-0">월 구매확정액이 3만원 이상 발생 시 장려 보너스와 직급 보너스를 받으실 수 있습니다</span>
                </div>
            </template>
        </tit-wrap-line>

        <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="768" class="v-data-table--default v-data-table--border">
            <template #[`item.salePrice`]="{ value }">
                <div class="d-inline-flex align-center font-size-16 font-size-md-20 grey--text text--darken-4">
                    <span class="d-flex align-center primary--text">
                        <strong>{{ value?.format?.() || 0 }}</strong>
                        <small class="font-size-14 font-weight-regular pl-2px">원</small>
                    </span>
                    <span class="font-size-14 px-8px">/</span>
                    <span class="d-flex flex-wrap align-center grey--text text--darken-4">
                        <strong>30,000</strong>
                        <small class="font-size-14 font-weight-regular pl-2px">원</small>
                    </span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import { mapActions, mapState } from "vuex";

const headers = [
    { align: "center", text: "현재 본인 등급", value: "userLevelName" },
    { align: "center", text: "이번 달 내 구매확정 금액", value: "salePrice" },
];

export default {
    components: {
        TitWrapLine,
        VerticalTable,
    },
    data: () => ({
        salePrice: 0,

        headers,
        loading: false,
    }),
    computed: {
        ...mapState(["user"]),
        items() {
            return [
                {
                    userLevelName: this.user?.level?.name || "등급 없음",
                    salePrice: this.salePrice,
                },
            ];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let params = {
                    responseType: "salePrice",
                    completedAt: [dayjs().startOf("month"), dayjs().endOf("month")].map((dayjs) => dayjs.toISOString()),
                };
                this.salePrice = (await api.v1.me.purchases.gets({ params }))?.salePrice || 0;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.getUser();
            }
        },
    },
};
</script>

<style></style>
