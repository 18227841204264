<template>
    <v-tabs :value="activeTab" fixed-tabs>
        <v-tab v-for="{ to, text, value } in tabs" :key="value" v-bind="{ to, value }">{{ text }}</v-tab>
    </v-tabs>
</template>

<script>
import { BONUS_TABS } from "@/assets/variables";
const tabs = Object.values(BONUS_TABS);

export default {
    computed: {
        activeTab() {
            const { tab } = this.$route.params;
            return BONUS_TABS[tab?.toUpperCase?.()]?.value || BONUS_TABS.WHOLE.value;
        },
        tabs() {
            const basePath = "/promoter";
            return tabs.map(({ path, ...tab }) => ({ ...tab, to: { path: `${basePath}/${path}` } }));
        },
    },
};
</script>

<style></style>
