<template>
    <v-tab-item :value="BONUS_WITHDRAW_TABS.CONFIRM_DEPOSIT_DONE.value">
        <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">
            출금이 완료되었습니다.
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col cols="12">
                    <v-btn block color="primary" class="v-size--xx-large" @click="$emit('close')">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import { BONUS_WITHDRAW_TABS, initBonus__debit } from "@/assets/variables";

import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: initBonus__debit }, // withdraw form
    },
    data: () => ({
        BONUS_WITHDRAW_TABS,
    }),
};
</script>
