<template>
    <client-page>
        <mypage-layout title="보너스 내역" aside="promoter">
            <div class="tabs-wrap">
                <bonus-tabs />
            </div>

            <!-- S: 현재 보너스 -->
            <page-section class="page-section--sm pt-0">
                <template #containerImmersive>
                    <bonus-card />
                </template>
            </page-section>
            <!-- E: 현재 보너스 -->

            <v-tabs-items touchless :value="tab">
                <bonus-whole />
                <bonus-goods />
                <bonus-grade />
                <bonus-sales />
                <bonus-debit />
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import { BONUS_TABS } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import BonusCard from "@/components/client/promoter/bonuses/bonus-card.vue";
import BonusTabs from "@/components/client/promoter/bonuses/bonus-tabs.vue";
import BonusWhole from "@/components/client/promoter/bonuses/bonus-whole.vue";
import BonusGoods from "@/components/client/promoter/bonuses/bonus-goods.vue";
import BonusGrade from "@/components/client/promoter/bonuses/bonus-grade.vue";
import BonusSales from "@/components/client/promoter/bonuses/bonus-sales.vue";
import BonusDebit from "@/components/client/promoter/bonuses/bonus-debit.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        BonusCard,
        BonusTabs,
        BonusWhole,
        BonusGoods,
        BonusGrade,
        BonusSales,
        BonusDebit,
    },
    computed: {
        tab() {
            const { tab } = this.$route.params;
            return BONUS_TABS[tab?.toUpperCase?.()]?.value || BONUS_TABS.WHOLE.value;
        },
    },
};
</script>
