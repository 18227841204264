var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "value": _vm.activeTab,
      "fixed-tabs": ""
    }
  }, _vm._l(_vm.tabs, function (_ref) {
    var to = _ref.to,
      text = _ref.text,
      value = _ref.value;
    return _c('v-tab', _vm._b({
      key: value
    }, 'v-tab', {
      to,
      value
    }, false), [_vm._v(_vm._s(text))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }