<template>
    <settlement-monthly-table v-bind="{ title, makeItems }" />
</template>

<script>
import SettlementMonthlyTable from "../settlements/settlement-monthly-table.vue";

const title = "월별 보너스 내역";
const makeItems = (data) => [
    {
        rowName: "보너스 총합",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.meta?.bonusAmount?.total }),
    },
];

export default {
    components: {
        SettlementMonthlyTable,
    },
    data: () => ({
        title,
        makeItems,
    }),
};
</script>

<style></style>
