<template>
    <v-tab-item :value="tab">
        <!-- S: image -->
        <v-card outlined rounded="">
            <v-img :aspect-ratio="892 / 660" src="/images/mypage/bonus-sales-banner.jpg" />
        </v-card>
        <!-- E: image -->

        <!-- S: 월별 보너스 내역 -->
        <page-section class="page-section--sm pb-0">
            <template #containerImmersive>
                <bonus-sales-monthly />
            </template>
        </page-section>
        <!-- E: 월별 보너스 내역 -->
    </v-tab-item>
</template>

<script>
import api from "@/api";
import { BONUS_TABS } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TableSearch from "@/components/client/dumb/table-search.vue";
import BonusSalesMonthly from "./bonus-sales-monthly.vue";

const tab = BONUS_TABS.SALES.value;

export default {
    components: {
        PageSection,
        TableSearch,
        BonusSalesMonthly,
    },

    data: () => ({
        tab,
    }),
};
</script>
