<template>
    <v-row align="center" class="row--xs txt txt--sm txt--dark">
        <v-col cols="auto">
            <v-btn text class="v-btn--text-fit" v-bind="{ loading }" @click="year -= 1">
                <v-icon size="24" color="grey">mdi-chevron-left</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="auto">
            <span class="txt txt--sm txt--dark font-weight-medium"> {{ year }}년 </span>
        </v-col>
        <v-col cols="auto">
            <v-btn text class="v-btn--text-fit" v-bind="{ loading }" @click="year += 1">
                <v-icon size="24" color="grey">mdi-chevron-right</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: Number, default: dayjs().year() },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        year: dayjs().year(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        year() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.year = this.value;
        },
        emit() {
            this.$emit("input", this.year);
        },
    },
};
</script>

<style></style>
