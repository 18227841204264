<template>
    <v-text-field v-model="query['searchValue']" placeholder="검색어를 입력하세요" v-bind="{ ...attrs_input, loading }" @keydown.enter="search">
        <template #append-outer>
            <v-btn v-bind="{ ...btn_primary3, ...$attrs, loading }" class="w-100 w-sm-80px" @click="search">검색</v-btn>
        </template>
    </v-text-field>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { btn_primary3 } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ["searchValue"]: query["searchValue"] || null,
});

export default {
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary3,

        query: initQuery(),
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        $route() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        search() {
            let { ...query } = this.query;

            if (!query["searchValue"]) delete query["searchValue"];
            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-outer {
    margin: 0 0 0 8px !important;
}
</style>
