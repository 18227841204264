<template>
    <settlement-monthly-table v-bind="{ title, makeItems }" />
</template>

<script>
import SettlementMonthlyTable from "../settlements/settlement-monthly-table.vue";

const title = "월별 보너스 내역";
const makeItems = (data) => [
    {
        rowName: "장려 보너스",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.meta?.bonusAmount?.sales }),
    },
    {
        rowName: "추천회원 매출액",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.meta?.purchaseAmount?.promotee }),
    },
    {
        rowName: "회원레벨",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.grade?.value ? `${data[key]?.grade?.value}lv` : "-" }),
    },
];

export default {
    components: {
        SettlementMonthlyTable,
    },
    data: () => ({
        title,
        makeItems,
    }),
};
</script>

<style></style>
