<template>
    <div>
        <tit-wrap-line>
            <template slot="titleContents">
                <div class="d-flex flex-column flex-sm-row align-sm-center">
                    <span class="tit tit--xxs">우대회원 달성 자격조건</span>
                    <span class="txt txt--sm pl-sm-8px mt-4px mt-sm-0">전달 본인 3만원 이상 구매확정 시 자격 충족</span>
                </div>
            </template>
        </tit-wrap-line>

        <v-row no-gutters align="center">
            <v-col cols="12" sm="auto">
                <div class="pt-16px pb-4px px-10px px-md-0 py-md-16px">
                    <span class="txt txt--xs">이번 달 내 구매확정 금액</span>
                </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="" class="text-right">
                <div class="pt-4px pb-16px px-10px px-md-0 py-md-16px">
                    <div class="d-inline-flex align-center font-size-16 font-size-md-20 grey--text text--darken-4">
                        <span class="d-flex align-center primary--text">
                            <strong>{{ salePrice?.format?.() || 0 }}</strong>
                            <small class="font-size-14 font-weight-regular pl-2px">원</small>
                        </span>
                        <span class="font-size-14 px-8px">/</span>
                        <span class="d-flex align-center grey--text text--darken-4">
                            <strong>30,000</strong>
                            <small class="font-size-14 font-weight-regular pl-2px">원</small>
                        </span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider />
    </div>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { PURCHASE_CLAIM_STATUSES } from '@/assets/variables';

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

export default {
    components: {
        TitWrapLine,
    },
    data: () => ({
        salePrice: 0,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let params = {
                responseType: "salePrice",
                claimStatus: JSON.stringify({ $nin: [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value] }),
                completedAt: [dayjs().startOf("month"), dayjs().endOf("month")].map((dayjs) => dayjs.toISOString()),
            };
            this.salePrice = (await api.v1.me.purchases.gets({ params }))?.salePrice || 0;
        },
    },
};
</script>

<style></style>
