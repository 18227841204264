var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('monthly-table', _vm._b({
    model: {
      value: _vm.year,
      callback: function ($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  }, 'monthly-table', {
    title: _vm.title,
    items: _vm.items,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }