var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "보너스 내역",
      "aside": "promoter"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('bonus-tabs')], 1), _c('page-section', {
    staticClass: "page-section--sm pt-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('bonus-card')];
      },
      proxy: true
    }])
  }), _c('v-tabs-items', {
    attrs: {
      "touchless": "",
      "value": _vm.tab
    }
  }, [_c('bonus-whole'), _c('bonus-goods'), _c('bonus-grade'), _c('bonus-sales'), _c('bonus-debit')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }