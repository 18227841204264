<template>
    <v-card rounded="" color="grey lighten-5" class="overflow-hidden">
        <v-row no-gutters>
            <!-- S: 내 직급 -->
            <v-col cols="12" sm="6" md="4">
                <card-sheet>
                    <template #head>
                        <span>내 직급</span>
                        <span class="primary--text pl-8px">{{ user?.title }}</span>
                    </template>
                    <v-responsive :aspect-ratio="5 / 2">
                        <v-card color="white" rounded="md" class="fill-height">
                            <v-layout justify-center align-center fill-height>
                                <span class="font-size-20 font-size-md-28 font-weight-bold"> {{ user?.level?.name || "등급 없음" }} </span>
                            </v-layout>
                        </v-card>
                    </v-responsive>
                    <template #foot>
                        <div class="mt-12px">
                            <card-sheet-foot :items="levelFootItems" />
                        </div>
                    </template>
                </card-sheet>
            </v-col>
            <!-- E: 내 직급 -->

            <!-- S: 나의 추천회원 -->
            <v-col cols="12" class="d-sm-none"><v-divider /></v-col>
            <v-col cols="auto" class="d-none d-md-block ml-n1px">
                <div class="h-100 py-18px py-md-24px">
                    <v-divider vertical />
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <card-sheet>
                    <template #head>
                        <span>나의 추천회원</span>
                    </template>
                    <v-responsive :aspect-ratio="5 / 2">
                        <v-card color="white" rounded="md" class="fill-height">
                            <v-layout justify-center align-center fill-height>
                                <span class="font-size-20 font-size-md-28 font-weight-bold"> {{ user?.meta?.promoteeCount?.promotees || "0" }} 명 </span>
                            </v-layout>
                        </v-card>
                    </v-responsive>
                    <template #foot>
                        <div class="mt-12px">
                            <card-sheet-foot :items="promoteeFootItems" />
                        </div>
                    </template>
                </card-sheet>
            </v-col>
            <!-- E: 나의 추천회원 -->

            <!-- S: 추천인 코드 -->
            <v-col cols="12" class="d-sm-none"><v-divider /></v-col>
            <v-col cols="auto" class="d-none d-md-block ml-n1px">
                <div class="h-100 py-18px py-md-24px">
                    <v-divider vertical />
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <card-sheet>
                    <template #head>
                        <span>추천인 코드</span>
                        <span class="primary--text pl-8px">{{ user?.code?.code }}</span>
                    </template>
                    <info-card-bank v-bind="user" />
                </card-sheet>
            </v-col>
            <!-- E: 추천인 코드 -->

            <!-- S: 홍보 URL -->
            <v-col cols="12"><v-divider /></v-col>
            <v-col cols="12">
                <div class="pa-18px px-md-20px py-md-24px">
                    <v-row align="center" class="row--sm">
                        <v-col>
                            <div class="txt txt--sm font-weight-medium grey--text text--darken-4 mb-4px">추천인 코드를 이용해 지인을 추천해 보세요.</div>
                            <div class="txt txt--xs">{{ promoterCodeFoot1 }}</div>
                            <div class="txt txt--xs">{{ promoterCodeFoot2 }}</div>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-large color="primary" @click="copy(promoterCodeFoot1)">홍보 URL 복사</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <!-- E: 홍보 URL -->
        </v-row>
    </v-card>
</template>

<script>
import CardSheet from "@/components/client/promoter/dashboard/card-sheet.vue";
import InfoCardBank from "@/components/client/promoter/dashboard/info-card-bank.vue";
import CardSheetFoot from "@/components/client/promoter/dashboard/card-sheet-foot.vue";

export default {
    components: {
        CardSheet,
        InfoCardBank,
        CardSheetFoot,
    },
    props: {
        user: { type: Object, default: () => ({}) },
    },
    computed: {
        levelFootItems() {
            return [{ term: "직급포인트", data: (this?.user?.score || 0)?.format?.() }];
        },
        promoteeFootItems() {
            let { consumers = 0, promoters = 0, vipprmtrs = 0 } = this.user?.meta?.promoteeCount || {};
            return [
                { term: "준회원", data: consumers?.format?.() },
                { term: "정회원", data: promoters?.format?.() },
                { term: "우수회원", data: vipprmtrs?.format?.() },
            ];
        },
        promoterCodeFoot1() {
            let { protocol, host } = document.location;
            return `${protocol}//${host}/join?promoterCode=${this.user?.code?.code}`;
        },
        promoterCodeFoot2() {
            let { protocol, host } = document.location;
            return `${protocol}//${host}/center/about?promoterCode=${this.user?.code?.code}`;
        },
    },
    methods: {
        copy(value) {
            navigator.clipboard.writeText(value);
        },
    },
};
</script>

<style></style>
