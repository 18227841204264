var render = function render(){
  var _vm$salePrice, _vm$salePrice$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', [_c('template', {
    slot: "titleContents"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-sm-center"
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("우대회원 달성 자격조건")]), _c('span', {
    staticClass: "txt txt--sm pl-sm-8px mt-4px mt-sm-0"
  }, [_vm._v("전달 본인 3만원 이상 구매확정 시 자격 충족")])])])], 2), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-0 py-md-16px"
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("이번 달 내 구매확정 금액")])])]), _c('v-spacer'), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-0 py-md-16px"
  }, [_c('div', {
    staticClass: "d-inline-flex align-center font-size-16 font-size-md-20 grey--text text--darken-4"
  }, [_c('span', {
    staticClass: "d-flex align-center primary--text"
  }, [_c('strong', [_vm._v(_vm._s(((_vm$salePrice = _vm.salePrice) === null || _vm$salePrice === void 0 ? void 0 : (_vm$salePrice$format = _vm$salePrice.format) === null || _vm$salePrice$format === void 0 ? void 0 : _vm$salePrice$format.call(_vm$salePrice)) || 0))]), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])]), _c('span', {
    staticClass: "font-size-14 px-8px"
  }, [_vm._v("/")]), _c('span', {
    staticClass: "d-flex align-center grey--text text--darken-4"
  }, [_c('strong', [_vm._v("30,000")]), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])])])], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }