var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-18px px-md-20px py-md-24px",
    attrs: {
      "tile": "",
      "color": "transparent",
      "loader-height": "100%"
    }
  }, [_vm.$slots.head ? _c('div', {
    staticClass: "txt txt--sm font-weight-medium grey--text text--darken-4 mb-12px"
  }, [_vm._t("head")], 2) : _vm._e(), _vm.$slots.default ? _c('div', [_vm._t("default")], 2) : _vm._e(), _vm.$slots.foot ? _c('div', [_vm._t("foot")], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }