<template>
    <monthly-table v-model="year" v-bind="{ title, items, loading }" />
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import MonthlyTable from "../../dumb/monthly-table.vue";

const itemKeys = new Array(12).fill(null).map((_, i) => (i + 1).toString().padStart(2, "0"));

export default {
    components: {
        MonthlyTable,
    },
    props: {
        title: { type: String, default: "월별 구매확정 금액" },
        makeItems: {
            type: Function,
            default: () => (data) => [
                {
                    rowName: "금액",
                    reducer: (o, key) => ({ ...o, [key]: data[key]?.meta?.purchaseAmount?.promoter }),
                },
                {
                    rowName: "회원등급",
                    reducer: (o, key) => ({ ...o, [key]: data[key]?.grade?.name }),
                },
            ],
        },
    },
    data: () => ({
        settlements: [],

        year: +dayjs().format("YYYY"),
        loading: false,
    }),
    computed: {
        items() {
            let data = itemKeys.reduce((o, key) => ({ ...o, [key]: this.settlements.find(({ settlementRound }) => settlementRound?.slice?.(-2) == key) }), {});
            return this.makeItems(data).map(({ rowName, reducer }) => ({ rowName, ...itemKeys.reduce(reducer, {}) }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        year() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.year = +dayjs().format("YYYY");
            this.loading = false;
            this.settlements = [];
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { year } = this;
                let { settlements } = await api.v1.promoter.settlements.gets({
                    params: { year },
                });
                this.settlements = settlements;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
