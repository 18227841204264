var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs"
  }, _vm._l(_vm.items, function (_ref) {
    var term = _ref.term,
      data = _ref.data;
    return _c('v-col', {
      key: term,
      staticClass: "txt txt--xs",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_vm._v(_vm._s(term))]), _c('span', {
      staticClass: "grey--text text--darken-4 pl-4px"
    }, [_vm._v(_vm._s(data))])]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }