<template>
    <client-page>
        <mypage-layout title="보너스 출금" aside="promoter">
            <!-- S: 현재 보너스 -->
            <bonus-card />
            <!-- E: 현재 보너스 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import BonusCard from "@/components/client/promoter/bonuses/bonus-card.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        BonusCard,
    },
};
</script>
