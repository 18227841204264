var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "문의하기",
      "aside": "promoter"
    }
  }, [_vm.accessToken ? _c('div', [_vm.$route.query._question === undefined ? _c('board-question-list', _vm._b({}, 'board-question-list', {
    code: _vm.code
  }, false)) : !_vm.$route.query._question ? _c('board-question-input', _vm._b({}, 'board-question-input', {
    code: _vm.code
  }, false)) : _vm.$route.query._question ? _c('board-question-view', _vm._b({}, 'board-question-view', {
    code: _vm.code
  }, false)) : _vm._e()], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }