<template>
    <v-row class="row--xs">
        <v-col v-for="{ term, data } in items" :key="term" cols="auto" class="txt txt--xs">
            <span>{{ term }}</span>
            <span class="grey--text text--darken-4 pl-4px">{{ data }}</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [] },
    },
};
</script>

<style></style>
