var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.BONUS_WITHDRAW_TABS.VERIFY_USER_IDENTITY.value
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0"
  }, [_vm._v(" 본인인증 후 출금이 완료됩니다. ")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.prev
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("이전")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        var _vm$$refs$kcpCert, _vm$$refs$kcpCert$aut;
        (_vm$$refs$kcpCert = _vm.$refs['kcp-cert']) === null || _vm$$refs$kcpCert === void 0 ? void 0 : (_vm$$refs$kcpCert$aut = _vm$$refs$kcpCert.auth) === null || _vm$$refs$kcpCert$aut === void 0 ? void 0 : _vm$$refs$kcpCert$aut.call(_vm$$refs$kcpCert);
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("본인인증 후 출금")]), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.withdraw
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }