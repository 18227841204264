var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 892 / 660,
      "src": "/images/mypage/bonus-sales-banner.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('bonus-sales-monthly')];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }