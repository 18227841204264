var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "color": "white",
      "rounded": "md"
    }
  }, [_c('div', {
    staticClass: "pa-12px pa-md-16px"
  }, [_c('div', {
    staticClass: "txt txt--sm font-weight-medium grey--text text--darken-4"
  }, [_vm._v("보너스 출금 계좌번호")]), _c('v-divider', {
    staticClass: "my-8px"
  }), _vm._l(_vm.items, function (_ref, index) {
    var term = _ref.term,
      data = _ref.data;
    return _c('div', {
      key: term,
      class: {
        'mt-8px': index != 0
      }
    }, [_c('v-row', {
      staticClass: "txt txt--xs",
      attrs: {
        "no-gutters": "",
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(term) + " ")]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "grey--text text--darken-4"
    }, [_vm._v(_vm._s(data || "-"))])])], 1)], 1);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }