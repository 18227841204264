<template>
    <v-tabs fixed-tabs>
        <v-tab v-for="{ text, to } in tabs" :key="text" v-bind="{ to }" exact>{{ text }}</v-tab>
    </v-tabs>
</template>

<script>
export default {
    computed: {
        tabs() {
            const { ...query } = this.$route.query;
            delete query.type;
            return [
                { text: "전체보기", to: { query } },
                { text: "준회원", to: { query: { ...query, type: "consumers" } } },
                { text: "정회원", to: { query: { ...query, type: "promoters" } } },
                { text: "우대회원", to: { query: { ...query, type: "vipprmtrs" } } },
            ];
        },
    },
};
</script>

<style></style>
